import { CTA, Brand, Navbar } from './components';
import React from 'react';
import { Footer, Blog, Possibility, Features, WhatGPT3, Header } from './containers';

import './App.css';

function App() {
  return (
    <div className="App">
   <div className="gradient__bg">
      <Navbar />
      <Header />
    </div>
    <Possibility />
    <Footer />
    </div>

  );
}

export default App;
