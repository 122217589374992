import React from 'react';
import gpt3Logo from '../../assets/log.png';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
  
    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={gpt3Logo} alt="gpt3_logo" />
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Social Media</h4>
        <p>Facebook</p>
        <p>LinkdIn</p>
        <p>Instagram</p>

      </div>
      <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions</p>
        <p>Privacy Policy</p>
        <p>about us</p>

      </div>
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p>adress</p>
        <p>+213549620595</p>
        <p>boularak.tech</p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2023 boularak.tech created by Boularak Oussama.</p>
    </div>
  </div>
);

export default Footer;
