import React, { useState } from 'react';
import ai from '../../assets/log.png';
import './navbar.css';

const Navbar = () => {

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          <img src={ai} />
          <span>Boularaktech</span>
        </div>
        <div className="gpt3__navbar-links_container">
          <p><a href="#home">Home</a></p>
          <p><a href="#possibility">Services</a></p>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
