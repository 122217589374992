import React from 'react';
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';
import './header.css';

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Let&apos;s Build Something amazing with us </h1>
      <p>Boularak Tech are the embodiment of creativity and craftsmanship, where every product and service is meticulously crafted to meet the highest standards of quality and innovation. Our dedication to perfection drives us to constantly push boundaries, to challenge the ordinary, and to create experiences that are nothing short of extraordinary.</p>

    </div>
    <div className="gpt3__header-image">
      <img src={ai} />
    </div>
  </div>
);

export default Header;
